import { graphql } from 'gatsby'
import React from 'react'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Gallery from '@browniebroke/gatsby-image-gallery'
import Container from '../components/Container'
import PageTitle from '../components/PageTitle'
import GalleryPicker from "../components/GalleryPicker";

const GalleryTemplate = ({ data }) => {
    const images = data.images.photos
    return (
        <Layout>
            <SEO title="Gallery"/>
            <Container>
                <PageTitle>Gallery</PageTitle>
                <GalleryPicker />
                <Gallery images={images}/>
            </Container>
        </Layout>
    )
}

export const query = graphql`
    query($id: String!) {
        images: contentfulGallery(contentful_id: {eq: $id}) {
            photos {
                title,
                description,
                thumb: gatsbyImageData(
                    width: 270,
                    height: 270
                ),
                full: gatsbyImageData(layout: FULL_WIDTH)
            }
        }
    }
`

export default GalleryTemplate
